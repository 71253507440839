<template>
  <div class="wrapper">
    <a-tooltip
      title="以当前的工作表（左表）为基础，将关联表（右表）中的字段添加进来。若右表中有左表中不存在的内容，则忽略这部分数据"
    >
      <div
        :class="['unit', { selected: value === 'left-join' }]"
        @click="$emit('input', 'left-join')"
      >
        <svg
          viewBox="0 0 1509 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
        >
          <path
            d="M29.563081 516.459501a478.33617 478.33617 0 0 0 470.958234 485.406692A478.33617 478.33617 0 0 0 970.557308 516.459501 478.33617 478.33617 0 0 0 500.521315 31.052809 478.028756 478.028756 0 0 0 29.563081 516.152087z"
            fill="#158EFC"
          ></path>
          <path
            d="M1011.443368 0.003996a482.94738 482.94738 0 0 0-256.075854 72.242288 3.07414 3.07414 0 0 0-1.53707 0h-1.844484A483.869622 483.869622 0 0 0 496.832348 0.003996 504.773773 504.773773 0 0 0 0.051338 511.848291a505.081187 505.081187 0 0 0 498.010666 512.151709 483.254794 483.254794 0 0 0 255.153612-73.471944 3.07414 3.07414 0 0 0 1.53707 0h0.614828a483.254794 483.254794 0 0 0 255.153612 72.549702A505.081187 505.081187 0 0 0 1509.454034 511.848291 505.081187 505.081187 0 0 0 1011.443368 0.003996z m-304.954679 916.401107a430.379587 430.379587 0 0 1-208.426685 53.490035 450.976325 450.976325 0 0 1-443.905803-458.046847A451.283739 451.283739 0 0 1 498.062004 53.801445a428.227689 428.227689 0 0 1 208.426685 53.797448 522.603784 522.603784 0 0 0 0 808.80621z m304.954679 53.490035a430.379587 430.379587 0 0 1-208.426685-53.490035 522.603784 522.603784 0 0 0 0-808.80621 428.227689 428.227689 0 0 1 208.426685-53.797448 451.283739 451.283739 0 0 1 443.905803 458.046846 450.976325 450.976325 0 0 1-443.905803 458.046847z"
            fill="#158EFC"
          ></path>
        </svg>
        <span class="label">左关联</span>
      </div>
    </a-tooltip>
    <a-tooltip
      title="与「左关联」正好相反，以右表为主表，移除左表中存在但是右表中不存在的数据"
    >
      <div
        :class="['unit', { selected: value === 'right-join' }]"
        @click="$emit('input', 'right-join')"
      >
        <svg
          viewBox="0 0 1509 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
        >
          <path
            d="M544.174102 511.848291a478.33617 478.33617 0 0 0 470.958234 485.406692 478.33617 478.33617 0 0 0 470.958234-485.406692A478.028756 478.028756 0 0 0 1015.132336 26.4416 478.33617 478.33617 0 0 0 544.174102 511.848291z"
            fill="#158EFC"
          ></path>
          <path
            d="M1011.443368 0.003996a482.94738 482.94738 0 0 0-256.075854 72.242288 3.07414 3.07414 0 0 0-1.53707 0h-1.844484A483.869622 483.869622 0 0 0 496.832348 0.003996 504.773773 504.773773 0 0 0 0.051338 511.848291a505.081187 505.081187 0 0 0 498.010666 512.151709 483.254794 483.254794 0 0 0 255.153612-73.471944 3.07414 3.07414 0 0 0 1.53707 0h0.614828a483.254794 483.254794 0 0 0 255.153612 72.549702A505.081187 505.081187 0 0 0 1509.454034 511.848291 505.081187 505.081187 0 0 0 1011.443368 0.003996z m-304.954679 916.401107a430.379587 430.379587 0 0 1-208.426685 53.490035 450.976325 450.976325 0 0 1-443.905803-458.046847A451.283739 451.283739 0 0 1 498.062004 53.801445a428.227689 428.227689 0 0 1 208.426685 53.797448 522.603784 522.603784 0 0 0 0 808.80621z m304.954679 53.490035a430.379587 430.379587 0 0 1-208.426685-53.490035 522.603784 522.603784 0 0 0 0-808.80621 428.227689 428.227689 0 0 1 208.426685-53.797448 451.283739 451.283739 0 0 1 443.905803 458.046846 450.976325 450.976325 0 0 1-443.905803 458.046847z"
            fill="#158EFC"
          ></path>
        </svg>
        <span class="label">右关联</span>
      </div>
    </a-tooltip>
    <a-tooltip title="仅保留左表和右表中都包含的数据，其余全部去掉">
      <div
        :class="['unit', { selected: value === 'inner-join' }]"
        @click="$emit('input', 'inner-join')"
      >
        <svg
          viewBox="0 0 1509 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
        >
          <path
            d="M1011.443368 0.003996a482.94738 482.94738 0 0 0-256.075854 72.242288 3.07414 3.07414 0 0 0-1.53707 0h-1.844484A483.869622 483.869622 0 0 0 496.832348 0.003996 504.773773 504.773773 0 0 0 0.051338 511.848291a505.081187 505.081187 0 0 0 498.010666 512.151709 483.254794 483.254794 0 0 0 255.153612-73.471944 3.07414 3.07414 0 0 0 1.53707 0h0.614828a483.254794 483.254794 0 0 0 255.153612 72.549702A505.081187 505.081187 0 0 0 1509.454034 511.848291 505.081187 505.081187 0 0 0 1011.443368 0.003996z m-304.954679 916.401107a430.379587 430.379587 0 0 1-208.426685 53.490035 450.976325 450.976325 0 0 1-443.905803-458.046847A451.283739 451.283739 0 0 1 498.062004 53.801445a428.227689 428.227689 0 0 1 208.426685 53.797448 522.603784 522.603784 0 0 0 0 808.80621z m304.954679 53.490035a430.379587 430.379587 0 0 1-208.426685-53.490035 522.603784 522.603784 0 0 0 0-808.80621 428.227689 428.227689 0 0 1 208.426685-53.797448 451.283739 451.283739 0 0 1 443.905803 458.046846 450.976325 450.976325 0 0 1-443.905803 458.046847z"
            fill="#158EFC"
          />
        </svg>
        <span class="label">内关联</span>
      </div>
    </a-tooltip>
    <a-tooltip title="两个表的数据都保留，但是不存在的字段内容将会为空">
      <div
        :class="['unit', { selected: value === 'full-join' }]"
        @click="$emit('input', 'full-join')"
      >
        <svg
          viewBox="0 0 1578 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
        >
          <path
            d="M512 512m-512 0a512 512 0 1 0 1024 0 512 512 0 1 0-1024 0Z"
            fill="#2D8CF0"
          ></path>
          <path
            d="M1066.666667 512m-512 0a512 512 0 1 0 1024 0 512 512 0 1 0-1024 0Z"
            fill="#2D8CF0"
          ></path>
          <path
            d="M789.376 85.333333A511.573333 511.573333 0 0 1 1024 515.754667a511.573333 511.573333 0 0 1-234.666667 430.464A511.573333 511.573333 0 0 1 554.666667 515.754667c0-178.346667 91.221333-335.402667 229.546666-427.093334L789.333333 85.333333z"
            fill="#2D8CF0"
          ></path>
        </svg>
        <span class="label">全关联</span>
      </div>
    </a-tooltip>
  </div>
</template>

<script>
/**
 * dirty code ...
 */
export default {
  props: {
    value: String
  },
  created() {
    this.check();
  },
  watch: {
    value() {
      this.check();
    }
  },
  methods: {
    check() {
      if (!this.value) {
        this.$emit("input", "left-join");
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.wrapper {
  display: flex;
  margin-top: 1em;
}
.unit {
  display: flex;
  align-items: center;
  margin-right: 18px;
  line-height: 1;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.12);
  height: 30px;
  padding: 0 14px;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.2s;
  opacity: 0.85;
  .label {
    margin-left: 10px;
  }
  &:hover {
    background: #f5f5f5;
  }
  &.selected {
    background: @blue-1;
    opacity: 1;
  }
}
</style>
