/**
 * 字符串搜索
 * @param {String} source 源字符串
 * @param {String} keyword 搜索关键词
 * @returns {Boolean} 是否包含关键词
 */
export function includes(source, keyword) {
  source = String(source || "").toLocaleLowerCase();
  keyword = String(keyword || "").toLocaleLowerCase();
  return source.includes(keyword);
}

export const ListItem = {
  functional: true,
  render(h, context) {
    const props = context.props;
    const source = props.source;
    return (
      <div
        class={[
          "list-item",
          props.itemChecked && props.itemChecked(source) ? "checked" : null,
          props.getItemClass ? props.getItemClass(source) : null
        ]}
        title={props.getItemTitle ? props.getItemTitle(source) : null}
        onClick={() => props.onSelect(source)}
      >
        {props.itemIcon ? (
          <span class="icon">{props.itemIcon(h, source)}</span>
        ) : null}
        {props.itemLabel ? (
          <span class="title">{props.itemLabel(h, source)}</span>
        ) : null}
        {props.itemAppend ? (
          <span class="append">{props.itemAppend(h, source)}</span>
        ) : null}
      </div>
    );
  }
};
