<template>
  <VirtualList
    ref="virtualList"
    placeholder="查找视图/表"
    style="padding: 2em; height: 100%; overflow: scroll"
    :loading="loading"
    :show-status="isJoinWithTable"
    :list="isJoinWithTable ? tables : []"
    :filter-keys="['tableAlias', 'tableName']"
    :extra-props="{ itemChecked, getItemTitle, itemIcon, itemLabel }"
    @select="onSelectTable"
    @search="searchText = $event"
  >
    <a-radio-group
      v-model="foreignTableType"
      slot="header-before"
      style="width: 100%;margin-bottom: 24px"
    >
      <a-radio-button value="view" style="width: 50%; text-align: center">
        视图
      </a-radio-button>
      <a-radio-button value="table" style="width: 50%; text-align: center">
        表
      </a-radio-button>
    </a-radio-group>
    <Datasheets
      v-if="!isJoinWithTable"
      slot="header-after"
      :default-datasheet="rule.foreignTableId"
      @select="onSelectDatasheet"
    />
  </VirtualList>
</template>

<script>
import * as schema from "@/views/datamodel/utils/resolve-schema";
import VirtualList from "@/views/datamodel/components/virtual-list";
import Datasheets from "./Datasheets";

export default {
  components: { VirtualList, Datasheets },
  props: {
    rule: Object
  },
  data() {
    return {
      loading: false,
      searchText: "",
      foreignTableType: this.rule.foreignTableType || "view",
      tables: []
    };
  },
  computed: {
    isJoinWithTable() {
      return this.foreignTableType !== "view";
    }
  },
  created() {
    this.getTables();
  },
  methods: {
    async getTables() {
      try {
        this.loading = true;
        this.tables = await schema.resolveTables();
      } finally {
        this.loading = false;
        // 30ms 之后再定位到已选中的表
        await new Promise(resolve => setTimeout(resolve, 30));
        const selectedTableId = this.rule.foreignTableId;
        const index = selectedTableId
          ? this.tables.findIndex(item => item.id === selectedTableId)
          : -1;
        const ref = this.$refs.virtualList;
        if (ref && index >= 0) {
          ref.scrollToIndex(index);
        }
      }
    },
    onSelectTable(item) {
      this.rule.foreignTableId = item.id;
      this.rule.foreignTableName = item.tableName;
      this.rule.foreignTableType = "table";
      // 进入下一步
      this.$emit("goto", 1);
    },
    onSelectDatasheet(item) {
      this.rule.foreignTableId = item.nodeId;
      this.rule.foreignTableName = item.nodeName;
      this.rule.foreignTableIcon = item.icon;
      this.rule.foreignTableType = "view";
      // 进入下一步
      this.$emit("goto", 1);
    },
    itemChecked(item) {
      return this.rule.foreignTableId === item.id;
    },
    getItemTitle(item) {
      const { tableName, tableAlias } = item || {};
      const alias = tableAlias === tableName ? "-" : tableAlias;
      return `表名：${tableName}\n别名：${alias}`;
    },
    // eslint-disable-next-line no-unused-vars
    itemIcon(h) {
      return <i class="ndl-icon-layers"></i>;
    },
    itemLabel(h, item) {
      return item.tableAlias || item.tableName;
    }
  }
};
</script>
