<template>
  <div class="main-wrapper">
    <Header v-if="rule" v-model="current" class="header" />
    <div v-if="rule" class="body">
      <component :is="currentComponent" :rule="rule" @goto="current = $event" />
    </div>
    <a-result v-else status="404" sub-title="未添加关联" class="body" />
    <Footer class="footer" v-on="$listeners" />
  </div>
</template>

<script>
import Header from "./Header";
import Footer from "./Footer";
import StepTables from "./StepTables";
import StepConditions from "./StepConditions";
import StepColumns from "./StepColumns";

export default {
  components: { Header, Footer },
  props: {
    rule: Object
  },
  data() {
    return {
      current: 0
    };
  },
  computed: {
    currentComponent() {
      return [StepTables, StepConditions, StepColumns][this.current] || null;
    }
  },
  created() {
    this.resetStep();
  },
  watch: {
    rule() {
      this.resetStep();
    }
  },
  methods: {
    resetStep() {
      /**
       * @type {import("./model-rule").Rule}
       */
      const rule = this.rule || {};
      const conditions = rule.conditions || [];
      if (!rule.foreignTableId) {
        this.current = 0;
      } else if (
        conditions.some(item => !(item.primaryColumnId && item.foreignColumnId))
      ) {
        this.current = 1;
      } else {
        this.current = 2;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.main-wrapper {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .header,
  .footer {
    flex-shrink: 0;
  }
  .body {
    flex: 1 1 0;
    overflow: auto;
  }
}
</style>
