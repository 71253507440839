<template>
  <a-modal
    centered
    width="64vw"
    :closable="false"
    :mask-closable="false"
    :keyboard="false"
    :body-style="{
      height: '78vh',
      padding: 0,
      display: 'flex'
    }"
    :footer="null"
  >
    <Sidebar ref="sidebar" v-model="selectedRule" :rules="rules" />
    <Main :rule="selectedRule" @cancel="$reject()" @confirm="onConfirm()" />
  </a-modal>
</template>

<script>
import * as schema from "@/views/datamodel/utils/resolve-schema";
import Sidebar from "./Sidebar";
import Main from "./Main";
import { Rule } from "./model-rule";

export default {
  components: { Sidebar, Main },
  props: {
    joins: Array,
    $resolve: Function,
    $reject: Function
  },
  data() {
    const joins = [].concat(this.joins || []);
    const rules = joins.map(item => new Rule(item));
    return {
      rules,
      selectedRule: null
    };
  },
  /**
   * 清空缓存，避免新建的视图不能被关联问题
   */
  destroyed() {
    schema.resolveIsogenyDatasheets.cache.clear();
  },
  methods: {
    /**
     * @todo 检查必填项
     */
    onConfirm() {
      this.$resolve(this.rules);
    }
  }
};
</script>
