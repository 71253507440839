<template>
  <a-result v-if="!rule.foreignTableId" status="500" sub-title="未选择关联表">
    <a-button slot="extra" type="primary" @click="$emit('goto', 0)">
      选择关联表
    </a-button>
  </a-result>
  <VirtualList
    v-else
    placeholder="查找字段"
    style="padding: 2em; height: 100%; overflow: auto"
    data-key="columnId"
    :loading="loading"
    :list="columns"
    :filter-keys="['columnName']"
    :extra-props="{ itemChecked, getItemTitle, itemIcon, itemLabel }"
    @select="onSelect"
  />
</template>

<script>
import VirtualList from "@/views/datamodel/components/virtual-list";
import * as schema from "@/views/datamodel/utils/resolve-schema";
import { Icon } from "@/constant/column-types";

export default {
  components: { VirtualList },
  props: {
    rule: Object
  },
  data() {
    return {
      loading: false,
      columns: []
    };
  },
  created() {
    this.getColumns();
  },
  watch: {
    "rule.foreignTableId"() {
      this.getColumns();
    }
  },
  methods: {
    async getColumns() {
      try {
        this.loading = true;
        /**
         * @type {import("./model-rule").Rule}
         */
        const rule = this.rule || {};
        if (!rule.foreignTableId) {
          return;
        }
        const data = await schema.resolveColumnsByDatasheetId(
          rule.foreignTableId
        );
        this.columns = data;
      } catch (err) {
        this.columns = [];
      } finally {
        this.loading = false;
      }
    },
    onSelect(item) {
      const columnId = item.columnId;
      const rule = this.rule;
      if (!rule.columns) {
        rule.columns = [];
      }
      const columns = rule.columns;
      const index = columns.findIndex(item => item.columnId === columnId);
      if (index >= 0) {
        columns.splice(index, 1);
      } else {
        columns.push({ ...item, columnId });
      }
    },
    itemChecked(item) {
      const columns = this.rule.columns || [];
      return columns.some(column => column.columnId === item.columnId);
    },
    getItemTitle(item) {
      const { fieldName, columnName, fieldType } = item || {};
      const alias = columnName === fieldName ? "-" : columnName;
      return `字段名：${fieldName}\n别名：${alias}\n字段类型：${fieldType}`;
    },
    // eslint-disable-next-line no-unused-vars
    itemIcon(h, item) {
      const type = item.columnType;
      return <i class={Icon[type]}></i>;
    },
    itemLabel(h, item) {
      return item.columnName || item.fieldName;
    }
  }
};
</script>
