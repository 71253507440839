<template>
  <a-steps
    type="navigation"
    :current="value"
    :style="{ boxShadow: '0px -1px 0 0 #e8e8e8 inset' }"
    @change="$emit('input', $event)"
  >
    <a-step title="选择关联视图/表" />
    <a-step title="设置关联条件" />
    <a-step title="选择输出字段" />
  </a-steps>
</template>

<script>
export default {
  props: {
    value: Number
  },
  watch: {
    value(value) {
      if (value < 0) {
        this.$emit("input", 0);
      } else if (value > 2) {
        this.$emit("input", 2);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.ant-steps {
  /deep/ .ant-steps-item-icon {
    width: 24px;
    height: 24px;
    line-height: 24px;
    vertical-align: middle;
  }
  /deep/ .ant-steps-item-content {
    vertical-align: middle;
  }
}
</style>
