<template>
  <div class="row">
    <div ref="leftColumn" class="selection" @click="selectLeftColumn()">
      <span v-if="leftColumn" class="icon">
        <i :class="leftColumn | iconClass"></i>
      </span>
      <span v-if="leftColumn" class="label">{{ leftColumn.columnName }}</span>
      <span v-else class="placeholder">请选择左表字段</span>
      <span class="arrow">
        <i class="ndl-icon-chevron-down"></i>
      </span>
    </div>
    <span class="operator">=</span>
    <div ref="rightColumn" class="selection" @click="selectRightColumn()">
      <span v-if="rightColumn" class="icon">
        <i :class="rightColumn | iconClass"></i>
      </span>
      <span v-if="rightColumn" class="label">{{ rightColumn.columnName }}</span>
      <span v-else class="placeholder">请选择右表字段</span>
      <span class="arrow">
        <i class="ndl-icon-chevron-down"></i>
      </span>
    </div>
    <span class="action" @click="$emit('remove')">
      <i class="ndl-icon-minus-circle"></i>
    </span>
  </div>
</template>

<script>
import workspace from "@/views/datamodel/models/workspace";
import selectColumn from "@/views/datamodel/components/context-select-column";
import * as schema from "@/views/datamodel/utils/resolve-schema";
import { Icon } from "@/constant/column-types";

export default {
  props: {
    rule: Object,
    condition: Object
  },
  data() {
    return {
      leftColumns: workspace.currentDatasheet.columns || [],
      rightColumns: []
    };
  },
  computed: {
    leftColumn() {
      const columnId = this.condition.primaryColumnId;
      return this.leftColumns.find(item => item.columnId === columnId) || null;
    },
    rightColumn() {
      const columnId = this.condition.foreignColumnId;
      return this.rightColumns.find(item => item.columnId === columnId) || null;
    }
  },
  async created() {
    /**
     * @type {import("./model-rule").Rule}
     */
    const rule = this.rule || {};
    this.rightColumns = await schema.resolveColumnsByDatasheetId(
      rule.foreignTableId
    );
  },
  filters: {
    iconClass(column) {
      if (column) {
        return Icon[column.columnType];
      }
      return "";
    }
  },
  methods: {
    selectLeftColumn() {
      const source = this.$refs.leftColumn;
      const columns = this.leftColumns;
      selectColumn({
        source,
        columns,
        onSelect: (item, context) => {
          this.condition.primaryColumnId = item.columnId;
          context.props.$selectColumn.hideContext();
        },
        // 设置 container 为当前组件 $el 以避免 z-index 问题
        container: this.$el,
        showFooter: false,
        showCheckbox: false
      });
    },
    selectRightColumn() {
      const source = this.$refs.rightColumn;
      const columns = this.rightColumns;
      selectColumn({
        source,
        columns,
        onSelect: (item, context) => {
          this.condition.foreignColumnId = item.columnId;
          context.props.$selectColumn.hideContext();
        },
        // 设置 container 为当前组件 $el 以避免 z-index 问题
        container: this.$el,
        showFooter: false,
        showCheckbox: false
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.row {
  background: #fafafa;
  border-radius: 10px;
  padding: 12px;
  margin: 12px 0;
  display: flex;
  align-items: center;
  line-height: 1.5;
  .operator {
    padding: 0 12px;
    flex-shrink: 0;
  }
  .action {
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
    margin-left: 12px;
  }
  .action:hover {
    color: @red-6;
  }
}
.selection {
  width: 0;
  flex: 1 1 0;
  background: @blue-1;
  height: 32px;
  padding: 0 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  .placeholder,
  .label {
    flex: 1 1 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .placeholder {
    color: fade(black, 45%);
  }
  .icon,
  .arrow {
    flex-shrink: 0;
    color: fade(black, 65%);
  }
  .icon {
    margin-right: 6px;
  }
}
</style>
