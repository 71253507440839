<template>
  <VirtualList
    ref="virtualList"
    class="list-wrapper ndl-antd"
    :data-key="dataKey"
    :data-sources="filteredList"
    :data-component="ListItem"
    :extra-props="{ ...extraProps, onSelect }"
  >
    <slot name="header-before" slot="header"></slot>
    <a-input-search
      slot="header"
      v-if="list.length > 10"
      v-model="search"
      style="margin-bottom: 12px"
      :placeholder="placeholder"
      @change="$emit('search', $event.target.value)"
    />
    <slot name="header-after" slot="header"></slot>
    <div slot="footer" v-if="showStatus">
      <a-skeleton v-if="loading" active />
      <a-empty
        v-else-if="!list.length"
        :image="IMAGE_URL"
        :image-style="{ height: '240px' }"
        description="暂无数据"
      />
    </div>
  </VirtualList>
</template>

<script>
import VirtualList from "vue-virtual-scroll-list";
import IMAGE_URL from "@/assets/images/ai_no_task.svg";
import * as helpers from "./helpers";

/**
 * @typedef {import("vue/types/vnode").VNode} VNode
 * @typedef {{
 *  itemChecked: (item: object) => boolean,
 *  getItemTitle: (item: object) => string,
 *  getItemClass: (item: object) => string,
 *  itemIcon: (h: function, item: object) => VNode,
 *  itemLabel: (h: function, item: object) => VNode,
 *  itemAppend: (h: function, item: object) => VNode
 * }} ExtraProps
 */
export default {
  components: { VirtualList },
  props: {
    loading: Boolean,
    dataKey: {
      type: String,
      default: "id"
    },
    list: {
      type: Array,
      required: true
    },
    filterKeys: Array,
    /**
     * @type {ExtraProps}
     */
    extraProps: Object,
    placeholder: String,
    // 是否展示 loading 状态或者空列表状态
    showStatus: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      IMAGE_URL,
      ListItem: helpers.ListItem,
      search: ""
    };
  },
  computed: {
    filteredList() {
      const search = this.search;
      const keys = this.filterKeys || [];
      if (!search || !keys.length) {
        return this.list;
      }
      return this.list.filter(item => {
        return keys.some(key => helpers.includes(item[key], search));
      });
    }
  },
  methods: {
    onSelect(item) {
      this.$emit("select", item);
    },
    scrollToIndex(index) {
      const ref = this.$refs.virtualList;
      if (ref) {
        ref.scrollToIndex(index);
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.list-wrapper {
  /deep/ div[role="header"],
  /deep/ div[role="group"],
  /deep/ div[role="footer"] {
    max-width: 420px;
    margin: auto;
  }
  /deep/ .list-item {
    height: 40px;
    padding: 0 8px 0 14px;
    margin: 6px 0;
    border-radius: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  /deep/ .list-item:hover {
    background: rgba(55, 53, 47, 0.08);
  }
  /deep/ .list-item.checked {
    background: @blue-1;
  }
  /deep/ .icon {
    color: @blue-6;
    flex-shrink: 0;
    margin-right: 12px;
    display: flex;
  }
  /deep/ .title {
    flex: 1 1 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
