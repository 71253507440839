<template>
  <div class="sidebar">
    <div class="title">
      <i class="ndl-icon-sliders"></i>
      <span>关联设置</span>
    </div>
    <!-- rule list -->
    <div class="list-wrapper">
      <div
        v-for="(item, index) in rules"
        :key="index"
        :class="['list-item', { active: item === value }]"
        @click="$emit('input', item)"
      >
        <i v-if="!item.foreignTableId" class="label">请选择关联表</i>
        <template v-else>
          <i v-if="item.isJoinWithTable" class="icon ndl-icon-layers"></i>
          <IconFont v-else class="icon" :type="item.foreignTableIcon" />
          <span :title="'关联到 - ' + item.name" class="label">
            {{ item.name }}
          </span>
        </template>
        <span class="remove" @click.stop="removeRule(index, item)">
          <i class="ndl-icon-close-circle-fill"></i>
        </span>
      </div>
    </div>
    <!-- button -->
    <div class="footer">
      <a-button ghost block type="primary" icon="plus" @click="addRule()">
        添加关联
      </a-button>
    </div>
  </div>
</template>

<script>
import IconFont from "@/components/iconfont";
import Rule from "./model-rule";

export default {
  components: { IconFont },
  props: {
    value: Object,
    /**
     * @type {Rule[]}
     */
    rules: Array
  },
  created() {
    this.autoSelect();
  },
  watch: {
    value(value) {
      const rules = this.rules || [];
      if (!rules.includes(value) && rules[0]) {
        this.$emit("input", rules[0]);
      }
    }
  },
  methods: {
    // 自动选中第一项
    autoSelect(autoCreate = true) {
      const rules = this.rules || [];
      if (autoCreate && !rules.length) {
        this.addRule();
      }
      if (!rules.includes(this.value)) {
        this.$emit("input", rules[0] || null);
      }
    },
    addRule() {
      const rule = new Rule();
      this.rules.push(rule);
      this.$emit("input", rule);
    },
    removeRule(index, item) {
      this.rules.splice(index, 1);
      if (item === this.value) {
        this.autoSelect(false);
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

@gutter: 12px;

.sidebar {
  width: 240px;
  flex-shrink: 0;
  background: #f5f5f5;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .title {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    & > span {
      margin-left: 6px;
      font-size: 13px;
    }
  }
  .list-wrapper {
    flex: 1 1 0;
    overflow: auto;
  }
  .title,
  .list-wrapper,
  .footer {
    padding: @gutter;
  }
}

.list-item {
  border-radius: 10px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 8px;
  margin: 3px 0;
  transition: background-color 0.2s;

  &:hover {
    background: rgba(0, 0, 0, 0.03);
  }
  &.active {
    background: rgba(0, 0, 0, 0.06);
  }

  .icon {
    margin-right: 6px;
    color: @blue-6;
    flex-shrink: 0;
    width: 16px;
    text-align: center;
  }
  .icon-symbol {
    height: 16px;
  }
  .label {
    flex: 1 1 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .remove {
    color: @red-6;
    flex-shrink: 0;
    display: none;
    margin-left: 6px;
    &:hover {
      opacity: 0.8;
    }
  }
  &:hover .remove {
    display: block;
  }
}
</style>
