<template>
  <!-- <div v-if="rule && rule.foreignTableId" class="ndl-antd" style="padding: 2em">
    <a-alert type="info" style="margin-bottom: 2em">
      <a slot="message">
        <i class="ndl-icon-layers"></i>
      </a>
      <span slot="message" style="margin: 0 6px">
        关联表 - {{ rule.foreignTableName }}
      </span>
    </a-alert>
  </div> -->
  <a-form
    v-if="rule && rule.foreignTableId"
    class="ndl-antd"
    style="padding: 2em"
  >
    <a-form-item label="关联方式">
      <JoinType v-model="rule.joinType" />
    </a-form-item>
    <a-form-item label="关联条件">
      <JoinCondition
        v-for="(item, index) in rule.conditions"
        :key="index"
        :rule="rule"
        :condition="item"
        @remove="onRemove(index, item)"
      />
      <a @click="onAdd()"><i class="ndl-icon-plus"></i> 添加条件</a>
    </a-form-item>
  </a-form>
  <a-result v-else status="500" sub-title="未选择关联表">
    <a-button slot="extra" type="primary" @click="$emit('goto', 0)">
      选择关联表
    </a-button>
  </a-result>
</template>

<script>
import JoinType from "./JoinType";
import JoinCondition from "./JoinCondition";
import { Condition } from "./model-rule";

export default {
  components: { JoinType, JoinCondition },
  props: {
    rule: Object
  },
  methods: {
    onAdd() {
      this.rule.conditions.push(new Condition());
    },
    onRemove(index) {
      this.rule.conditions.splice(index, 1);
    }
  }
};
</script>
