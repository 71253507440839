import * as schema from "@/views/datamodel/utils/resolve-schema";
import workspace from "@/views/datamodel/models/workspace";

export class Condition {
  /**
   * @param {{
   *  primaryColumnId: string,
   *  foreignColumnId: string
   * }} opts
   */
  constructor(opts) {
    this.primaryColumnId = "";
    this.foreignColumnId = "";
    Object.assign(this, opts);
  }
}

export class Rule {
  /**
   * @param {{
   *  joinType: string
   *  foreignTableId: string,
   *  foreignTableName: string,
   *  foreignTableType: string,
   *  foreignTableIcon: string,
   *  columns: object[],
   *  conditions: Condition[]
   * }} opts
   */
  constructor(opts) {
    opts = opts || {};
    // joinType enum: { left-join, right-join, inner-join, full-join }
    this.joinType = opts.joinType || "left-join";
    this.foreignTableId = opts.foreignTableId || "";
    this.foreignTableName = opts.foreignTableName || "";
    // foreignTableType enum: { table, view }
    this.foreignTableType = opts.foreignTableType || "view";
    // 关联到视图时展示相应视图 icon
    this.foreignTableIcon = opts.foreignTableIcon || null;
    this.columns = opts.columns || [];
    const conditions = opts.conditions || [{}];
    this.conditions = conditions.map(item => new Condition(item));
    // 同步最新的名称、图标
    this.updateMeta();
  }

  get name() {
    return this.foreignTableName || this.foreignTableId;
  }
  /**
   * true: 关联到表，false: 关联到视图
   */
  get isJoinWithTable() {
    return this.foreignTableType !== "view";
  }

  /**
   * foreignTableId 对应的表/视图有可能已经修改名字
   */
  async updateMeta() {
    if (this.isJoinWithTable) {
      // 关联到表
      const tables = await schema.resolveTables();
      const tableId = this.foreignTableId;
      const table = tables.find(item => item.id === tableId);
      if (table) {
        this.foreignTableName = table.tableAlias || table.tableName;
      }
    } else {
      // 关联到视图
      const datasheets = workspace.currentWorkspace.children;
      const datasheetId = this.foreignTableId;
      const datasheet = datasheets.find(item => item.nodeId === datasheetId);
      if (datasheet) {
        this.foreignTableName = datasheet.nodeName;
        this.foreignTableIcon = datasheet.icon;
      }
    }
  }
}

export default Rule;
