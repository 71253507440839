<template>
  <div class="tree-wrapper">
    <Tree
      v-for="item in workspaceTree.children"
      readonly
      :key="item.nodeId"
      :node="item"
      :add-class="addClass"
      @select="onSelect"
    />
  </div>
</template>

<script>
import * as schema from "@/views/datamodel/utils/resolve-schema";
import { Workspace } from "@/views/datamodel/models/workspace";
import workspace from "@/views/datamodel/models/workspace";
import Tree from "@/views/datamodel/components/tree";

export default {
  components: { Tree },
  props: {
    defaultDatasheet: String
  },
  data() {
    return {
      workspaceTree: []
    };
  },
  async created() {
    const cloneWorkspace = new Workspace(workspace);
    (function walk(tree) {
      if (tree.isFolder) {
        for (const item of tree.children) {
          walk(item);
        }
        return;
      }
      tree.isogeny = false;
    })(cloneWorkspace);
    this.workspaceTree = cloneWorkspace;
    // Same Origin Policy，只能关联同源视图
    const datasheetId = workspace.currentDatasheetId;
    const data = await schema.resolveIsogenyDatasheets(datasheetId);
    for (const item of data) {
      const node = cloneWorkspace.getNodeById(item.datasheetId);
      if (node) {
        node.isogeny = item.isogeny;
      }
    }
  },
  async mounted() {
    const defaultDatasheet = this.defaultDatasheet;
    if (!defaultDatasheet) {
      return;
    }
    const node = this.workspaceTree.getNodeById(defaultDatasheet);
    if (node) {
      node.parent.toggleExpand();
    }
    // 等待展开后渲染完子节点
    await this.$nextTick();
    const elm = this.$el.querySelector("." + defaultDatasheet);
    if (elm) {
      elm.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  },
  methods: {
    addClass(node) {
      return {
        // 文件夹或同源视图(isogeny: true)不禁用，其余都禁用
        disabled: !(node.isFolder || (node.isDatasheet && node.isogeny)),
        [node.nodeId]: true,
        checked: node.nodeId === this.defaultDatasheet
      };
    },
    /**
     * @param {import("@/views/datamodel/models/workspace").Workspace} item
     */
    onSelect(item) {
      if (item.isFolder) {
        item.toggleExpand();
      } else if (item.isDatasheet && item.isogeny) {
        // 同源视图才能选择
        this.$emit("select", item);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.tree-wrapper {
  /deep/ .tree-node {
    border-radius: 4px;
  }
  /deep/ .tree-node.checked {
    background: #e6f7ff;
  }
  /deep/ .tree-node.disabled {
    opacity: 0.35;
    cursor: not-allowed;
  }
  /deep/ .tree-node.disabled:hover {
    background: none;
  }
}
</style>
